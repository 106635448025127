<template>
  <transition name="fade">
    <div>
      <div style="width: 100%; height: 100%; text-align: center; padding-top: 100px;" v-if="state.loaderEnabled">
        <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
      </div>
      <div class="d-flex flex-column flex-xl-row" v-if="!state.loaderEnabled">
        <div class="flex-lg-row-fluid ms-lg-5">
          
          <div class="common-layout">
            <el-container style="border-radius: 0.475rem" class="card">

              <el-header style="border-radius: 0.475rem; padding-top: 13px !important;">

                <el-row>
                  <el-col :span="12">
                    <a class="btn btn-sm btn-primary mt-0" @click="addSite()">
                      <span class="svg-icon svg-icon-1"><inline-svg src="media/icons/duotune/arrows/arr087.svg" /></span>{{$t("Ajouter un nouveau site")}}
                    </a>
                    <!--
                    <a class="btn btn-light-primary mt-0">
                      <span class="svg-icon svg-icon-1"><inline-svg src="media/icons/duotune/arrows/arr076.svg" /></span>Importer des sites
                    </a>
                    -->
                  </el-col>
                  <el-col :span="12" style='text-align: right;'>
                    <span class="fs-6 text-dark mb-2">
                      {{$t("Afficher les sites")}}&nbsp;
                    </span>
                    <el-radio-group v-model="state.displaySite" size="large">
                      <el-radio-button label="carte" >{{$t("Sur une carte")}}</el-radio-button>
                      <el-radio-button label="liste" >{{$t("En listing")}}</el-radio-button>
                    </el-radio-group>
                  </el-col>
                </el-row>

               
                <!--
                <a class="btn btn-light-primary w-100 mt-2 mb-7">
                  <span class="svg-icon svg-icon-1"><inline-svg src="media/icons/duotune/arrows/arr076.svg" /></span>Importer des sites
                </a>
                -->

              </el-header>
              <el-main style="padding-top: 0px;">

            <div class="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
            <div class="row g-5 g-xl-12">
              <div class="col-xxl-12" v-show="state.displaySite === 'carte'">
                <div id="mapContainer" style="height: calc(100vh - 268px);border-radius: 0.475rem; box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 18%);"></div>
              </div>
              <div class="col-xxl-12" v-show="state.displaySite === 'liste'">
                <div class="" style="min-height: calc(100vh - 268px);" >
                  <div class="card-header border-0 pt-6">
                    <SearchAndFilter
                      :listInitial="state.initialMyList"
                      :key="state.initialMyList"
                      :filters="tableFilters"
                      :searchDefault="state.searchDefault"
                      @returnSearch="state.sitesList = $event"
                      @returnLoadingDatatable="state.loadingDatatable = $event"
                      ExcelFilename="Stillnetwork_Sites"
                      :ExcelFormat="ExcelFormat"
                    ></SearchAndFilter>
                  </div>
                  <div class="card-body pt-5">
                    <Datatable
                      v-if="state.sitesList && state.sitesList.length > 0"
                      :table-data="state.sitesList"
                      :table-header="tableHeader"
                      :enable-items-per-page-dropdown="true"
                      :empty-table-text="$t('Aucun site à afficher.')"
                      :key="state.loadingDatatable"
                    >
                      <template v-slot:cell-adr_libelle="{ row: data }">
                        <span class="text-primary" v-if="data.adr_c_site_label">
                          {{ data.adr_c_site_label }}
                        </span>
                        <span class="text-danger" v-else>
                          <div class="badge badge-light-danger w-100px bt-5 text-danger">
                            {{$t("Aucun libellé")}}
                          </div>
                        </span>
                      </template>
                      <template v-slot:cell-adr_ligne_1="{ row: data }">
                        <span class="text-dark" v-if="data.adr_ligne_1">
                          {{ data.adr_ligne_1 }}
                        </span>
                        <span class="text-dark" v-if="data.adr_ligne_2">
                          <br />
                          {{ data.adr_ligne_2 }}
                        </span>
                        <span class="text-dark">
                          <br />
                          {{ data.adr_cp }} - {{ data.adr_ville }}
                        </span>
                        <span class="text-dark" v-if="data.adr_pays">
                          <br />
                          {{ data.adr_pays }}
                        </span>
                      </template>
                      <template v-slot:cell-contacts="{ row: data }">
                        <span class="w-150px fs-5" v-if="data.contacts" v-html="data.contacts"></span>
                        <span class="text-secondary" v-else>
                          <div class="badge badge-light-dark">
                            {{$t("Aucun contact")}}
                          </div>
                        </span>
                      </template>
                      <template v-slot:cell-status="{ row: data }">
                        <span class="text-secondary" v-if="data.status" v-html="data.status"> </span>
                      </template>
                      <template v-slot:cell-adr_seq="{ row: data }">
                        <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark" @click="modifSite(data.adr_seq)">
                          <span class="svg-icon svg-icon-1"> <inline-svg src="media/icons/duotune/coding/cod001.svg" /> </span>{{$t("Modifier le site")}}
                        </a>
                        <!--
                        <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark" :test="data.adr_seq" @click="state.drawer = true">
                          <span class="svg-icon svg-icon-1"> <inline-svg src="media/icons/duotune/general/gen004.svg" /> </span>Voir le détails
                        </a>
                        -->
                      </template>
                    </Datatable>
                  </div>
                </div>
              </div>
            </div>
          </div>

              </el-main>
            </el-container>
          </div>


          


        </div>
      </div>
    </div>
  </transition>
  <el-drawer v-model="state.drawer" title="" :with-header="false" size="90%" modal-class="">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark">TEST</h3>
        <div class="card-toolbar">
          <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5" @click="state.drawer = false">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body">
        <headerCommande mCode="154" card-classes="mb-12"></headerCommande>
      </div>
    </div>
  </el-drawer>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
// import { useStore } from "vuex";
import mAxiosApi from "@/api";
import mAxios from "axios";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as moment from "moment";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import HeaderCommande from "@/views/still/commandes/headerCommande.vue";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({

  name: "customer-details",
  components: {
    Datatable,
    HeaderCommande,
    SearchAndFilter,
  },
  setup() {
    let mymap;
    const router = useRouter();
    const state = reactive({
      loaderEnabled: false,
      loadingDatatable: false,
      initialMyList: [],
      sitesList: "",
      listDates: [],
      displaySite: router.currentRoute.value.params.code ? "liste" : "carte",
      filterSite: "all",
      map: null,
      drawer: ref(false),
      showModal: false,
      idSite: 0,
      searchDefault: router.currentRoute.value.params.code ? router.currentRoute.value.params.code + "" : "",
    });
    const { t } = useI18n()

    const tableHeader = ref([
      {
        name: "Libellé",
        key: "adr_libelle",
        sortable: true,
      },
      {
        name: "Adresse",
        key: "adr_ligne_1",
        sortable: true,
      },
      {
        name: "Contact",
        key: "contacts",
        sortable: true,
      },
      {
        name: "Statut",
        key: "status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "adr_seq",
        sortable: true,
      },
    ]);

    const tableFilters = ref([]);

    const ExcelFormat = ref({
      Libellé: "adr_libelle",
      "Adresse L1": "adr_ligne_1",
      "Adresse L2": "adr_ligne_2",
      CP: "adr_cp",
      Ville: "adr_ville",
      Pays: "adr_pays",
      Contact: "contact_txt",
      Statut: "statusTxt",
    });

    onMounted(async () => {
      // INIT
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Outils de déploiement", []);
      state.loaderEnabled = false;
      const mAxiosGen = mAxios.create({
        withCredentials: false,
      });

      // MAP
      const map = L.map("mapContainer").setView([46.05, 11.05], 5);
      L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png", {
        attribution: "&copy;Stillnetwork",
      }).addTo(map);
      mymap = map;
      var customPane = map.createPane("customPane");
      customPane.style.zIndex = 399;

      // GET SITES
      let myList = await mAxiosApi.prototype.getAxios("/getSites");
      // console.log('sites: ', myList);

      let myListDates = await mAxiosApi.prototype.getAxios("/getSitesDates");

      state.listDates = myListDates.results;
      state.initialMyList = myList.results;
      state.sitesList = myList.results;
      state.loadingDatatable = true;
      state.showModal = false;
      let objUpdate = {};

      for (var i = 0; i < myList.results.length; i++) {
        const obj = myList.results[i];
        const stringAdr = encodeURI("street=" + obj.adr_ligne_1 + " " + obj.adr_ligne_2 + "&postcode=" + obj.adr_cp +  "&city=" + obj.adr_ville + "&country="+ (obj.adr_pays ? obj.adr_pays : 'france'));
        const statusVar = state.listDates.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => item.adr_seq === obj.adr_seq
        );
        _statusSite(obj, statusVar);

        if (obj.adr_lat == null || obj.adr_lat.length == 0) {
          obj.adr_lat = "";

          const response = await mAxiosGen.get(
            "https://api.geoapify.com/v1/geocode/search?" +
              stringAdr +
              "&limit=5&apiKey=1b48259b810e48ddb151889f9ea58db0"
          );

          if (response.data.features.length > 0) {
            obj.adr_lat = response.data.features[0].geometry.coordinates[1];
            obj.adr_lng = response.data.features[0].geometry.coordinates[0];

            objUpdate = {
              adr_lat: String(
                response.data.features[0].geometry.coordinates[1]
              ),
              adr_lng: String(
                response.data.features[0].geometry.coordinates[0]
              ),
            };

            await mAxiosApi.post("/updateAdresse/" + obj.adr_seq, objUpdate);
            _addMarkerMap(obj, map);
          } else {
            obj.adr_lat = "-1";
            obj.adr_lng = "-1";

            objUpdate = {
              adr_lat: String(obj.adr_lat),
              adr_lng: String(obj.adr_lat),
            };

            await mAxiosApi.post("/updateAdresse/" + obj.adr_seq, objUpdate);
            _addMarkerMap(obj, map);
          }
        } else {
          if (obj.adr_lat !== "-1") {
            _addMarkerMap(obj, map);
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.addEventListener('zanimalfound', (e: any) => modifSite(e.detail.siteId));  //console.log(e.detail.siteId)  e.detail.siteId
    });

    onBeforeUnmount(() => {
      mymap.remove();
    });

    onUnmounted(() => {
      window.removeEventListener('zanimalfound', modifSite);
    });
   
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function _addMarkerMap(obj, map) {

      var iconColor = new L.Icon({
        iconUrl:   "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-" + obj.typeMaker + ".png",
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41], iconAnchor: [12, 41],  popupAnchor: [1, -34], shadowSize: [41, 41],  });

      const buttonMap = `<div class="mb-2"><h3>${obj.adr_c_site_label === null ? '' : obj.adr_c_site_label + '<hr>'}</h3></div>
                         <div class="mb-2"><h5>${obj.adr_ligne_1}</h5></div>
                         <div class="mb-2"><h5>${obj.adr_ligne_2}</h5></div>
                         <div class="mb-2"><h5>${obj.adr_cp} - ${obj.adr_ville}</h5></div>
                         <div class="mt-10 text-primary p-3" style="box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 18%);">
                          ${obj.statusTxt}
                         </div>
                         <br>
                         <a class="btn btn-sm btn-active-secondary me-2 mt-5 btn-bg-primary btn-text-white" onclick="window.dispatchEvent(new CustomEvent('zanimalfound', {detail: {siteId: ${obj.adr_seq}}}));">
                           ${t("Modifier les détails du site")}
                         </a>`;

      L.marker([obj.adr_lat, obj.adr_lng], { icon: iconColor })
        .addTo(map)
        .bindPopup(buttonMap);
    }
    
    function addSite() {
      // createEditSiteRef.value?.addSite();
      router.push({  name: "addupdatesite", params: { code: 0 } });
    }
    
    function modifSite(idSite) {
      router.push({  name: "addupdatesite", params: { code: idSite } });
    }

    function _statusSite(obj, statusVar) {
      if (statusVar.length === 0) {
        obj.status = `
          <div class="badge badge-light-dark" >${t("Aucune livraison n'est prévue sur ce site")}</div>
        `;
        obj.statusTxt = `${t("Aucune livraison n'est prévue sur ce site")}`;
        obj.typeMaker = 'grey';
      } else {
        const mDate = moment
          .default(statusVar[0].the_date)
          .format("DD.MM.YYYY");
        const mPluriel = statusVar.length - 1 > 1 ? t("s") : "";
        let mOther =
          statusVar.length - 1 > 0
            ? ` + ${statusVar.length - 1} ${t("autre")}${mPluriel} ${t("projet")}${mPluriel}`
            : "";
        if (statusVar[0].type_recherche === "date_livraison_future") {
          obj.status = `
          <div class="badge badge-primary py-3" style="font-size: 0.900em;" >${t("Prochaine livraison prévue le")} ${mDate}</div><br / >
          <div class="badge badge-light-dark" >${t("Concerne le projet")} ${statusVar[0].pro_code} ${mOther}</div>
        `;
          obj.statusTxt = `
          ${t("Prochaine livraison prévue le")} ${mDate}. ${t("Concerne le projet")} ${statusVar[0].pro_code} ${mOther}
        `;
          obj.typeMaker = 'blue';
        } else if (statusVar[0].type_recherche === "commande_date") {
          obj.status = `
          <div class="badge badge-primary py-3" style="font-size: 0.900em;" >${t("Livraison projet")} ${statusVar[0].pro_code} (${t("Date non communiquée")})</div><br / >
          <div class="badge badge-light-dark" >${mOther}</div>
        `;
          obj.typeMaker = 'blue';
          obj.statusTxt = `
          ${t("Livraison projet")} ${statusVar[0].pro_code} (${t("Date non communiquée")}) ${mOther}
        `;
        }
      }
    }

    return {
      router,
      state,
      mymap,
      tableHeader,
      tableFilters,
      ExcelFormat,
      addSite,
      modifSite,
    };
  },
});
</script>
